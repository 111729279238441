export type CUSTOMER_TYPE = 'SYSTEM' | 'CHANNEL' | 'PRODUCTION_HOUSE' | 'CASTING_AGENCY' | 'ARTIST' | 'SHORT_FILMS' | 'ADVERTISEMENT';

export const PUBLIC_CUSTOMER_TYPE_LIST = [
  { key: 'CHANNEL', label: 'Channel' },
  { key: 'PRODUCTION_HOUSE', label: 'Production House' },
  { key: 'CASTING_AGENCY', label: 'Casting Agency' },
  { key: 'SHORT_FILMS', label: 'Short Films Maker' },
  { key: 'ADVERTISEMENT', label: 'Ad Agency' },
]

export const CUSTOMER_TYPE_LIST_MAP = {
  CHANNEL: 'Channel',
  PRODUCTION_HOUSE: 'Production House',
  CASTING_AGENCY: 'Casting Agency',
  ARTIST: 'Artist',
  SHORT_FILMS: 'Short Films Maker',
  ADVERTISEMENT: 'Ad Agency',
}

export const PLAN_STATUS = {
  ACTIVE: 'Active',
  TRIAL: 'Trial',
  TRIAL_EXPIRED: 'Trial Expired',
  SUBSCRIPTION_EXPIRED: 'Subscription Expired',
}

export const PLAN_NAME = {
  BASIC: 'Basic',
  PRO: 'Pro',
  ENTERPRISE: 'Enterprise',
}
export type CUSTOMER_STATUS = 'PAID' | 'UNPAID';

export type ROLES = 'CUSTOMER_ADMIN' | 'CUSTOMER_USER' | 'MODERATOR' | 'SYSTEM_ADMIN' | 'USER';

export enum USER_ROLE {
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
  MODERATOR = 'ROLE_MODERATOR',
  CUSTOMER_ADMIN = 'ROLE_CUSTOMER_ADMIN',
  SYSTEM_ADMIN = 'SYSTEM_ADMIN',
}